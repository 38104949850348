import React, { useState, useEffect, useRef } from 'react'
import firebase from '../components/firebase'

import ConsoleTemplate, {adminUsers} from '../components/consoleTemplate'

import { getFirestore, collection,  getDoc, getDocs, query, where, orderBy, setDoc, addDoc, doc} from "firebase/firestore";
import { getStorage, ref, getDownloadURL, listAll, deleteObject }  from "firebase/storage";

import {Avatar, Container, Tabs, Tab, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemSecondaryAction, Autocomplete, FormControlLabel, ImageList, ImageListItem , ImageListItemBar,  Button, Dialog, DialogActions, DialogContent, DialogTitle,  TextField, Switch,  IconButton, Select, FormControl, InputLabel, Input, Chip, MenuItem, Collapse } from '@mui/material';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';


import {saveAs} from 'file-saver'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DownloadingIcon from '@mui/icons-material/DownloadingOutlined';

import FirebaseFileUpload from '../components/firebase-fileupload';
import TabPanel from '../components/tabPanel'
const axios = require('axios').default;

//import MomentUtils from '@date-io/moment'

//import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

const db = getFirestore();
const storage = getStorage();


const EventEditView = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    //const [isUploading, setIsUploading] = useState(false)
    const [hostUsers, setHostUsers] = useState([]);
    const [guestUsers, setGuestUsers] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    //const [products, setProducts] = useState([]);
    const [activeProducts, setActiveProducts] = useState([]);
    //const [visibleProducts, setVisibleProducts] = useState([]);
    const [productSelectOpen, setProductSelectOpen] = useState(false);
    const [auctionSelectOpen, setAuctionSelectOpen] = useState(false);
    const [tags, setTags] = useState([]);
    const [activeTab, setActiveTab] = useState("info");
    const [productSearchText, setProductSearchText] = useState(""); 
    const [totalSales, setTotalSales] = useState(0);
    const [netSales, setNetSales] = useState(0);
    const [itemsSold, setItemsSold] = useState(0);
    const [noOfUsers, setNoOfUsers] = useState(0);
    const [donations, setDonations] = useState(0);
    const [recordings, setRecordings] = useState([]);
    const [recordingUsers, setRecordingUsers] = useState([]);
    const [expandFiles, setExpandFiles] = useState("");
    const [polls, setPolls] = useState([]);
    const [eventRepeat, setEventRepeat] = useState(0);
    const [eventRepeatTimes, setEventRepeatTimes] = useState(1);
    const [auctions, setAuctions] = useState([]);
    const [statDialog, setStatDialog] = useState(false);
   //var selectAuctionProduct = false;
   var selectedTags = useRef([]);
   var selectedGuests = useRef([]);

   const eventid = props.navigation.getParam("id");
    

    const [state, setState] = useState({
        name: "",
        eventDate: new Date(),
        image: "",
        isActive: false,
        isArchive: false,
        isLive: false,
        isAuction: false,
        isTest: false,
        isFeatured: false,
        description: "",
        products: [],
        tags: [],
        host: "",
        hostAvatar: "",
        hostname: "",
        hostEmail: "",
        hostVerified: false,
        guestHosts: [],
        id:"",
        theme: "", 
        liveShippingIntl:  -1,
        liveShippingLocal: 8,
        inviteCode: "",
        activeAuctionProduct: "",
        productsInAuction: [],
        auctionProductDetails:{},
    })

    useEffect( () => {
        

        loadHostsInfo();
        loadTags();
        getAllProducts();
        getEventAuctions();
        if(eventid) {
            loadEventInfo(eventid);
            
        }
    }, [])
    
    const getEventAuctions = async () => {

        try {
        const result = await getDocs(query(collection(db, "event-auctions"), where("eventid", "==", eventid)));
        console.log("auction result");
        console.log(result);
        if(result.size > 0) {
            const docs = result.docs;
            const auctions = [];
            docs.forEach( (item) => {
                const data = item.data();
                data.id = item.id;
                auctions.push(data);
            });
            
            auctions.sort( (a, b) => b.start - a.start);
           
            setAuctions(auctions);
        }
        } catch (err){
            console.error("Error loading auctions");
            console.error(err);
        }
    }

    const placeAuctionOrder= async (auctionid, uid ) => {
        try {
        const url = "https://api.exchvnge.co/orders/placeAuctionOrder";
        const data = {
            eventid: eventid,
            auctionid: auctionid,
            uid: uid,
        }
        const orderResult = await axios.post(url, data);
        } catch (err){
            console.log("auction order error");
            console.log(err);
        }
    }

    const loadTags = async () => {
        try {
            const result = await getDocs(query(collection(db, "tags"), orderBy("name", "asc")));
            const loadedTags = [];
            if( result.size> 0) {
                const docs = result.docs;
                docs.forEach( (item) => {
                    const data = item.data();
                    data.id = item.id;
                    loadedTags.push(data);
                });

                setTags(loadedTags);
            }

        }catch(err){
            console.error("Error loading Tags");
            console.error(err);

        }
    }

    const getUserStats = async (eventid) =>{

    }

    const loadHostsInfo = async () => {
        try {
            
            var result;
      
            result = await getDocs(query(collection(db, "profiles"),  orderBy("displayname", "asc")));
                //await firestore.collection("profiles").where("isHost", "==", true).orderBy("email", "asc").get();
           
            
            if(result.size > 0 ){
                const docs = result.docs;
                var userResults = [];
                var loadedHosts = [];
                var loadedGuests = [];

                docs.forEach( (item) => {
                    const data = item.data();
                    //console.log(data);
                    const obj = { label: data.displayname +" (" + data.email +")", displayname: data.displayname, email: data.email, uid: data.uid, avatar: data.image, isVerified: data.isVerified }
                    
                    if(data.isHost === true){
                        
                        loadedHosts.push(obj);
                        loadedGuests.push(obj);
                    } else if(data.isVendor === true){
                        
                        loadedGuests.push(obj);
                    }
                    

                    //userResults.push(obj);

            
                })
                setHostUsers(loadedHosts);
                setGuestUsers(loadedGuests);
            //    console.log(userResults)
            }
        } catch (err) {
            console.log("Error loading users");
            console.log(err);
        }
    }



    const getEventSummary = async (eventid) => {
        try {
            const orderResult = await getDocs(query(collection(db, "orders"), where("eventid", "==", eventid)));
           
            //console.log(orderResult);
            if(!orderResult.empty) {
                const orderDocs = orderResult.docs;
                var totalSales = 0;
                var netSales = 0;
                var itemsSold = 0;
             //   var noOfUsers = 0;
                orderDocs.forEach( (item) => {
                    const data = item.data();
                    if(data.status !== "Merged" && data.status !== "Cancelled" && data.status !== "Returned" && data.status !== "Refunded") {
                    totalSales += parseFloat(data.total);
                    netSales += parseFloat(data.subtotal);
                    itemsSold += data.products.length;
                    }
                 //   noOfUsers += data.noOfUsers;
                })
                setTotalSales(totalSales);
                setNetSales(netSales);
                setItemsSold(itemsSold);
              //  setNoOfUsers(noOfUsers);
            }

            const userResult = await getDocs(query(collection(db, "event-stats"), where("eventid", "==", eventid)));
            if(!userResult.empty){
                setNoOfUsers(userResult.size);
            }

            const donationsResult = await getDocs(query(collection(db, "donations"), where("eventid", "==", eventid)));
            if(!donationsResult.empty) {
                const donationsDocs = donationsResult.docs;
                var donations = 0;
                donationsDocs.forEach( (item) => {
                    const data = item.data();
                    donations += parseFloat(data.amount);
                })
                setDonations(donations);
            }

            const pollsResult = await getDocs(query(collection(db, "event-polls"), where("eventid", "==", eventid)));
            if(!pollsResult.empty) {
                const pollsDocs = pollsResult.docs;
                var polls = [];
                pollsDocs.forEach( (item) => {
                    const data = item.data();
                    data.id = item.id;
                    polls.push(data);
                })
                setPolls(polls);
            }

        } catch(err){
             console.log("Error loading event info");
            console.log(err)
        }
    }

    const loadEventInfo = async (eventid) => {
        setIsLoading(true)
        try {
        
        const result = await getDoc(doc(db, "events", eventid));
        //await firestore.collection("events").doc(eventid).get();
       
        if (result.exists) {
            
            const doc = result.data();
           // console.log(doc);
            selectedTags.current = doc.tags;
           
            setState( {
                ...state, 
                id: eventid,
                name: doc.name, 
                description: doc.description, 
                image: doc.image,
                eventDate: new Date(doc.eventDate.toDate()),
                isActive: doc.isActive,
                isArchive: doc.isArchive,
                isLive: doc.isLive, 
                isTest: doc.isTest != null ?? false,
                isFeatured: doc.isFeatured ?? false,
                isAuction: doc.isAuction != null ? doc.isAuction : false,
                tags: doc.tags ?? [],
                host: doc.host,  
                hostAvatar: doc.hostAvatar, 
                hostname: doc.hostname,
                hostVerified: doc.hostVerified ?? false,
                guestHosts: doc.guestHosts ?? [],
                inviteCode: doc.inviteCode ?? "",
                activeAuctionProduct: doc.activeAuctionProduct ?? "",
                products: doc.products != null ? doc.products : [],
                productsInAuction: doc.productsInAuction ?? [],
                liveShippingIntl: doc.liveShippingIntl ?? -1,
                liveShippingLocal: doc.liveShippingLocal ?? 8,
                auctionProductDetails: doc.auctionProductDetails ?? {},
                hostEmail: doc.hostEmail,
            
                }  )
        }
        console.log("Event summary");
        getEventSummary(eventid);
        getEventRecordingUsers(eventid);
        setIsLoading(false)
        } catch(err) {
            console.log("Error loading event info");
            console.log(err)
            setIsLoading(false)
        }
    }

    
   

    const onActiveChange = (event) => {
      
        setState( {
            ...state,
            isActive: !state.isActive,
            isTest: false,
        })
    }

    const onAuctionChange = (event) => {
        setState( {
            ...state, isAuction: !state.isAuction

        })
    }

    const onArchiveChange = (event) => {
        setState( {
            ...state, isArchive: !state.isArchive

        })
    }


    const onTestChange = (event) => {
        console.log(state.isTest);
        setState( {
            ...state, isTest: !state.isTest, 
        })
    }
    
    const onFeaturedChange = (event) => {
        setState( {
            ...state, isFeatured: !state.isFeatured,
        });
    }

    const updateEvent = async () => {
       
        setIsLoading(true)
        try {
            var eventid = props.navigation.getParam("id");

             
           // const firestore = firebase.firestore();

            const eventObj = state;
            
            console.log("Update event ");
            console.log(eventObj);
           


            eventObj.updatedByName = await sessionStorage.getItem("displayname");
            eventObj.updatedUid = await sessionStorage.getItem("uid");
            eventObj.updatedOn = new Date();


            if (eventid != null) {
               
                    await setDoc(doc(db, "events", eventid), eventObj, {merge: true});
               // }
                //await firestore.collection("events").doc(eventid).set(eventObj, {merge: true});
            } else {
                eventObj.createdByName = await sessionStorage.getItem("displayname");
                eventObj.createdByUid = await sessionStorage.getItem("uid");
                eventObj.createdOn = new Date();

                console.log(eventRepeatTimes + "  / " + eventRepeat)

                if(eventRepeatTimes > 0 && eventRepeat > 0) {
                    console.log("recurring events");
                    for(let i=0; i<eventRepeatTimes; i++) {
                        let eventDate = new Date(eventObj.eventDate);
                        let nextEvent = JSON.parse(JSON.stringify(eventObj));
                        eventRepeat < 30 ? eventDate.setDate(eventDate.getDate() + (eventRepeat * i)) : eventDate.setMonth(eventDate.getMonth() + i);
                        nextEvent.eventDate = eventDate;
                        //console.log("event "+i+ " : " + eventObj.eventDate + "/" + nextEvent.eventDate);
                       const addResult = await addDoc(collection(db, "events"), nextEvent);
                    }
                    //return false;

                } else {
                    console.log("single event");
                     const addResult = await addDoc(collection(db, "events"), eventObj);
               
                    //eventid = addResult.id;
                }
                
            } 


    
            setIsLoading(true)
            props.navigation.navigate("eventsLanding");


        }catch(err) {
            console.log("Error updating event");
            console.log(err);
            setIsLoading(false)
        } 
    }

    const cancelUpdate = () => {
        props.navigation.navigate("eventsLanding")
    }

    const getAllProducts = async () => {
        try {
            const productResult = await getDocs(query(collection(db, "products"), where("isActive", "==", true), orderBy("name", "asc") ));
            //const productResult = await getDocs(query(collection(db, "products"), where("isActive", "==", true), orderBy("name", "asc") ));

            if(!productResult.empty) {
                const productDocs = productResult.docs;
                const products = [];
                productDocs.forEach( (product, index) => {
                    const productData = product.data();
                    productData.id = product.id;
                    products.push(productData);
                });
                console.log("active products");
                console.log(products);
                setActiveProducts(products);
            
                  
                }

        
        } catch (err) {
            setAlertMessage("Error loading products");
            setAlertType("error");
            setShowAlert(true);
            console.log(err);
        }
    }

    

   

    const onAddProduct = async (productid, toAdd, isAuction) => {
      //  toAdd ? console.log("add " + productid) : console.log("remove " + productid);
        var products = isAuction ? state.productsInAuction : state.products;
        
        

        if(toAdd) {
            
            products.push(productid);

            if(isAuction){
                const auctionDefaults = {"start": 1, "reserve": 0, "buynow":0, "minbid":0, "timer":10};
                const auctionDetails = state.auctionProductDetails;
                auctionDetails[productid] = auctionDefaults;

                setState({...state, productsInAuction: products, auctionProductDetails:auctionDetails});
            } else {
                setState({...state, products: products});
            }
        } else {  
            products.splice(products.indexOf(productid), 1);
            if(isAuction){
                
                const auctionDetails = state.auctionProductDetails;
                delete auctionDetails[productid];
                
                setState({...state, productsInAuction: products, auctionProductDetails:auctionDetails});
                
            } else {
                setState({...state, products: products});
            }
            
        }
        
        

    }

    const onProductSearch = (productName) => {
        setProductSearchText(productName);
    }

    const onTagsChange = (event, child) => {
        
        
       
        const selected = child.props.children;
        var tagInList = false;
        selectedTags.current.forEach( (tag, index) => {
            //console.log(tag);
            if(tag === selected) {
                console.log("remove " + tag)
                selectedTags.current.splice(index, 1);
                tagInList = true;
                
            }
        });
        if(!tagInList) {
            selectedTags.current.push(selected);   
        }
       setState( {
            ...state,
            tags: selectedTags.current
        }) 

        
    }
    
    const onGuestsChange = (event, child) => {
        console.log(child.props.value);
        const selected = child.props.value;
        var guestInList = false;
        selectedGuests.current.forEach( (guest, index) => {
            if(guest.uid === selected.uid){
                selectedGuests.current.splice(index, 1);
                guestInList = true;
            }
        });

        if(!guestInList) {
            selectedGuests.current.push(selected);   
        }
        console.log("Current guests: ");
        console.log(selectedGuests.current);
        const guestHosts = [];
        for(var i = 0; i < selectedGuests.current.length; i++) {
            //console.log(selectedGuests.current[i].uid);
            //guestHosts[selectedGuests.current.uid] = selectedGuests.current[i];
            const host = selectedGuests.current[i];
            guestHosts.push({
                uid: host.uid,
                email: host.email,
                image: host.avatar,
                name: host.displayname,
            })
        }   

        setState( {
            ...state, guestHosts: guestHosts
        })

        

    }
   // new guest host inputs
   const onRemoveGuest = (uid) => {
     //   console.log("remover guest " + uid);

        const selected = state.guestHosts;
        for(var i=0; i<selected.length; i++) {
            if(selected[i].uid === uid) {
                selected.splice(i, 1);
            }
        }
        setState({...state, guestHosts: selected});
   }

   const onAddGuest = (guest) => {
     if(guest.uid !== ""){ 
       var guestInList = false;
       for(var i=0; i<state.guestHosts.length; i++) {
            if(state.guestHosts[i].uid === guest.uid) {
                guestInList = true;
                break;
            }
       }

       if(!guestInList) {
           
        const selected = state.guestHosts;
        selected.push(guest);
        setState({...state, guestHosts: selected});
            
       }
    }
   }

   const getEventRecordingUsers = async (eventid) => {
        try {
            if(eventid !== null) {
                const storageRef = ref(storage, "live-recordings/"+eventid+"/");
                const folders = await listAll(storageRef);
               const files = [];
              folders.prefixes.forEach( async (folderRef) =>  {
                    console.log("folder " + folderRef.name);
                    
                    const userDoc = await getDoc(doc(db, "profiles", folderRef.name));
                    
                    if(userDoc.exists) {
                        const user = {"uid": userDoc.id, "displayname": userDoc.data().displayname, "avatar": userDoc.data().avatar, "files": []};
                          
                        files.push(user);
                    }

              });
              //console.log("user files ", files);
                setRecordingUsers(files);
            }
        } catch (err) {
            console.error(err);
        }
   }

   const getUserRecordedFiles = async (eventid, userid) => {
        try {
            console.log("get user files " + eventid + " " + userid);
            if(eventid && userid){
                const storageRef = ref(storage, "live-recordings/"+eventid+"/"+userid+"/");
                const files = await listAll(storageRef);
               
                console.log("files", files.items);
                const userFiles = [];
                files.items.forEach( async (fileRef) => {
                    const fileObj = {"name": fileRef.name, "path": fileRef.fullPath};
                    userFiles.push(fileObj);
                }); 
                console.log("user files ", userFiles);
                setRecordings(userFiles);

            }

        } catch (err){ 
            console.error(err);

        }
   }

   const downloadVideo = async (path) => {
        const url = await getDownloadURL(ref(storage, path));
        console.log(url);
        window.open(url, "_blank");
   }

   const cleanupTSFiles = async (eventid) => {
         try {
            setIsLoading(true);
              const storageRef = ref(storage, "live-recordings/"+eventid+"/");
              const folders = await listAll(storageRef);
              console.log(folders);
              folders.prefixes.forEach( async (folderRef) =>  {
                 const files = await listAll(folderRef);
                    files.items.forEach(async (fileRef) => {
                        
                        if(!fileRef.name.endsWith(".mp4")) {
                            console.log("removed " + fileRef.name);
                            await deleteObject(fileRef);
                         
                        }
                        ///deleteObject(fileRef);
                    });
              });
              setIsLoading(false);
         } catch (err) {
            setIsLoading(false);
              console.error(err);
         }
   }


   const downloadDonorList = async () => {
        try {
            setIsLoading(true);
            const result = await getDocs(query(collection(db, "donations"), where("eventid", "==", eventid)));
            let donors = [];
            let csvString = "NAME,EMAIL,AMOUNT\n";
            if(!result.empty) {
                const donationsDocs = result.docs;
                var donations = 0;
                donationsDocs.forEach( (item) => {
                    const data = item.data();
                    csvString += data["displayname"] + "," + data["email"] +"," + data["amount"]+"\n";
                  //  donations += parseFloat(data.amount);
                });
                console.log(csvString);
                saveAs( new Blob([ csvString ], {type: "text/csv;charset=utf-8"}), "Donations_"+state.name+".csv");

                
            }
            setIsLoading(false);

        } catch (err){
            console.error(err);
            setAlertType("error");
            setAlertMessage("Unable to generate CSV File. Please try again");
            setShowAlert(true);
            setIsLoading(false);
        }
   }


   const downloadUserList = async () => {
    try {
        setIsLoading(true);
        console.log("download user list");
        const result = await getDocs(query(collection(db, "event-stats"), where("eventid", "==", eventid)));

       // console.log(result);
        var csvString = "Username, OS, OSVersion, AppVersion\n";
         if(!result.empty) {
            const userData= result.docs;
           // console.log(subscriberData);
        
            userData.forEach( (userDoc) => {
           
            const user = userDoc.data();
            if(user["displayname"] !== undefined && user["displayname"] !== "") {
                csvString += `${user["displayname"]}, ${user["os"]}, ${user["osVersion"]}, ${user["appVersion"]} \n`;//user["displayname"]+"," + user["os"+"\n";
            }
           });
           saveAs( new Blob([ csvString ], {type: "text/csv;charset=utf-8"}), "users-list-"+eventid+".csv");
          /*   const users = subscriberData["users"];
            var csvstring = "";
            
            users.forEach(( user) => {
       
               csvstring += user["displayname"]+", "+user["email"]+","+user["date"].toDate()+"\n";
            })
            console.log(csvstring);
            saveAs( new Blob([ csvstring ], {type: "text/csv;charset=utf-8"}), eventname+".csv"); */
        } 

        setIsLoading(false);
    }catch (err){
        setAlertType("error");
        console.log(err);
        setAlertMessage("Unable to generate CSV File. Please try again");
        setShowAlert(true);
        setIsLoading(false);
    }
   }

   const downloadPollData = async (pollid) => {
    try {
        setIsLoading(true);
        console.log("get poll " + pollid);
        const result = await getDoc(doc(db, "event-polls", pollid));
       
        if(result.exists){
            console.log("poll data", result.data());
            let csvString = "Poll: "+result.data().name+"\n";
            let entries = "";
            let results = [];
            let resultsString = "SUMMARY\n";
            const votes = result.data().votes;
            
            const profilesSnapshot = await getDocs(query(collection(db, "profiles"), where("isActive", "==", true)));
            const activeProfiles = [];
            profilesSnapshot.forEach(doc => {
                activeProfiles.push(doc.data()); // Add uid to set
            });


           
            //console.log(votes);
            for(let i=0; i<votes.length; i++) {
                if(votes[i]["displayname"].trim() !== "" && votes[i]["displayname"] !== undefined && votes[i]['option'] !== undefined && votes[i]['option'].trim() !== ""){

                    //find in activeProfiles if the uid is present


                    let isVerified = activeProfiles.find(profile => profile.uid === votes[i]["uid"])?.isVerified;
                   
        
                    
                        entries += votes[i]["displayname"] +","+votes[i]["option"] +","+ (isVerified ? "✅": "❌") + "\n";
                        let count = 1;
                    
                    if(results[votes[i]["option"]] !== undefined) {
                        count = results[votes[i]["option"]] + 1;
                    }
        
                    results[votes[i]["option"]] = count;
                }
            } 

        

            for (const [key, value] of Object.entries(results)) {
                resultsString += key + ","+value+"\n";
            }

            resultsString += ",Total Votes: " + votes.length + "\n\n";
            
            csvString = csvString + "\n\n" + resultsString + "\n\n" + "User, Vote, Verified Buyer\n"+ entries;

            saveAs( new Blob([ csvString ], {type: "text/csv;charset=utf-8"}), "poll-"+result.data().name+".csv"); 
        }
        setIsLoading(false);
    } catch (err){
        setAlertType("error");
        console.log(err);
        setAlertMessage("Unable to generate CSV File. Please try again");
        setShowAlert(true);
        setIsLoading(false);
    }
   }

   const downloadChatLog = async () => {
    try {
        setIsLoading(true);
        console.log("download chat log");
        const result = await getDocs(query(collection(db, "event-chat"), where("eventid", "==", eventid), orderBy("timestamp", "desc")));

       // console.log(result);
        var csvString = "Display Name, Message, Time, Deleted\n";
         if(!result.empty) {
            const chatData= result.docs;
           // console.log(subscriberData);
          
           chatData.forEach( (chatDoc) => {
           
            const chat = chatDoc.data();
            
            csvString += chat["sender"]+","+chat["message"]+","+chat["timestamp"].toDate()+","+chat["deleted"]+"\n";
           });
           saveAs( new Blob([ csvString ], {type: "text/csv;charset=utf-8"}), "chat-log-"+eventid+".csv");
          /*   const users = subscriberData["users"];
            var csvstring = "";
            
            users.forEach(( user) => {
       
               csvstring += user["displayname"]+", "+user["email"]+","+user["date"].toDate()+"\n";
            })
            console.log(csvstring);
            saveAs( new Blob([ csvstring ], {type: "text/csv;charset=utf-8"}), eventname+".csv"); */
        } 

        setIsLoading(false);
    }catch (err){
        setAlertType("error");
        console.log(err);
        setAlertMessage("Unable to generate CSV File. Please try again");
        setShowAlert(true);
        setIsLoading(false);
    }
   }


   const repeatTimes = [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];


    return (

        <LocalizationProvider dateAdapter={AdapterMoment}>
        <ConsoleTemplate {...props} isLoading={isLoading} tab={1} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
             
             <h2>Event Details - {state.name}</h2>
             <div className="brand-edit-form edit-form">
             <Container className="form-tabs">
                 <Tabs value={activeTab} onChange={ (e, tab) => setActiveTab(tab)}>
                 <Tab label="Event Info" value="info" /> 
                 <Tab label="Shop" value="shop" />
                
                 <Tab label="Auction" value="auction" />
                <Tab label="Statistics" value="statistics" />
                 <Tab label="Recordings" value="recordings" />
                 </Tabs>
                


             </Container>

             <TabPanel name="info" activeTab={activeTab}>
                <TextField  fullWidth={true} label="Event Name" type="text" className="form-input-field" placeholder="Event Name" id="name" required value={state.name} onChange={ (event) => { setState({...state, name: event.target.value})}} />
                <TextField className="form-input-field" label="Description" fullWidth={true} placeholder="Description" aria-label="Event description" value={state.description} onChange={ (event) => { setState( { ...state, description:event.target.value })}}/>
                <DateTimePicker className="form-date-input form-input-field" label="Event Date" variant="standard" 
                    renderInput={ (props) => <TextField fullWidth={true} {...props} /> }
                    value={state.eventDate} onChange={ (newDate) => { setState({...state, eventDate: newDate.toDate()})}} />
                 
                 

                 {!eventid && 
                    <FormControl sx={{ "minWidth": "200px"}} className="multi-select select-category form-input-field">
                            <InputLabel variant="standard" id="frequency-select-label" >No. of Times</InputLabel>
                            <Select label="Repeat Frequency" id="frequencies" value={eventRepeat} onChange={ (e) => {
                                
                                setEventRepeat(e.target.value);
                            } }  >   
                            <MenuItem value={0}>One Time Event</MenuItem>
                            <MenuItem value={1}>Daily</MenuItem>
                            <MenuItem value={7}>Weekly</MenuItem>
                            <MenuItem value={30}>Monthly</MenuItem>
                            </Select>
                        </FormControl>
}
{!eventid && eventRepeat > 0 &&
                        <FormControl sx={{ "minWidth": "200px"}} className="multi-select select-category form-input-field">
                            <InputLabel variant="standard" id="frequency-select-label" >No of Times</InputLabel>
                            <Select label="Repeat Frequency" id="frequencies" value={eventRepeatTimes} onChange={ (e) => {
                              
                                setEventRepeatTimes(e.target.value);
                            } }  >   
                            
                            {repeatTimes.map( (times) => <MenuItem key={times} value={times}>{times}</MenuItem>)}
                                
           
                            </Select>
                        </FormControl> 
}
                <FirebaseFileUpload id="image-upload" className="form-image-upload" label="Cover Image" accept="image/*" name="image1" storagePath="live-events/" onSuccess={ (path)=>{ setState({...state, image: path})}} preview={state.image} onRemovePreview={ () => {setState({...state, image: ""}) }}/> 
                <FormControlLabel className="form-input-field" control={
                    <Switch onChange={onActiveChange}  checked={state.isActive} color="secondary" />
                } label="Active" />
                <hr />
                <FormControlLabel className="form-input-field" control={
                <Switch onChange={onAuctionChange} checked={state.isAuction} color="secondary" />
                } label="Auction" />
                <hr />
              
                <FormControlLabel className="form-input-field" control={
                    <Switch onChange={onTestChange} checked={state.isTest} color="secondary" />
                } label="Preview" />
                <hr />
                { adminUsers.includes(sessionStorage.getItem("hash")) &&
                <FormControlLabel className="form-input-field" control={
                    <Switch onChange={onFeaturedChange} checked={state.isFeatured} color="secondary" />
                } label="Featured Event (For future use)" />

                }
                <Autocomplete fullWidth={true} id="host" options={hostUsers} autoSelect={true} autoHighlight={true} openOnFocus={true}
                    value={state.hostname !== "" ? state.hostname + " (" + state.hostEmail+")" : ""}
                    renderOption = { (props, option, state) => <li {...props} key={option.id} >{option.label}</li>}
                    renderInput = { (params) => <TextField className="form-input-field" {...params} label="Host" variant="outlined" inputProps={{...params.inputProps}} />}
                    onChange={ (event, value, reason, details) => { 
                        if(value != null) {
                        setState( {...state, 
                            hostname: value.displayname,
                            host: value.uid,
                            hostEmail: value.email,
                            hostAvatar: value.avatar,
                            hostVerified: value.isVerified,
                        })
                    }
                    }}
                />
              
                    
                <Autocomplete fullWidth={true} id="host" options={guestUsers} autoSelect={true} autoHighlight={true} openOnFocus={true}
                    value={state.hostname !== "" ? state.hostname + " (" + state.hostEmail+")" : ""}
                    renderOption = { (props, option, state) => <li {...props} key={option.id} >{option.label}</li>}
                    renderInput = { (params) => <TextField className="form-input-field" {...params} label="Add Guest Host" variant="outlined" inputProps={{...params.inputProps}} />}
                    onChange={ (event, value, reason, details) => { 
                        if(value !== null ) {
                        onAddGuest({ 
                            uid: value.uid,
                            name: value.displayname,
                            image: value.avatar,
                            email: value.email,
                        });
                    }
                    
                    }}
                />
                        <div className="guest-host-chips">
                            {
                                state.guestHosts.map( (guest) => {
                                 
                                    return <Chip key={guest.uid} label={guest.name + " (" + guest.email + ")"} variant="filled" className="chip" onDelete={ () => onRemoveGuest(guest.uid)}/> 
                                })                            
                            }
    
    </div>  
           

                <FormControl fullWidth={true} className="multi-select select-category form-input-field">
                            <InputLabel variant="standard" id="brand-select-label" shrink={true}>Tags</InputLabel>
                            <Select id="product-tags" multiple value={state.tags} onChange={ onTagsChange } 
                                input={<Input id="select-tag-chips" />}
                                renderValue={
                                    (selected) => (
                                        <div className="select-chips tag-chips">
                                            {selected.map(( tag) => <Chip key={tag} label={tag} className="tag-chip" />)}
                                        </div>
                                    )
                                }
                            >   
                            {tags.map (( tag ) => <MenuItem  key={tag.id} value={tag.id}>{tag.name}</MenuItem>)}
                            </Select>

                         
                        </FormControl>
             </TabPanel>
             <TabPanel name="shop" activeTab={activeTab}>
                 <Button variant="contained" color="buttonDark" onClick={ () => setProductSelectOpen(true)}>Add Product to Shop</Button>
                <ImageList cols={4} rowHeight={150}>
                    {state.products.map ((productid, index) => {
                        
                        var product = {};
                        for(var i=0; i<activeProducts.length; i++) {
                            if(activeProducts[i].id === productid) {
                                product = activeProducts[i];
                            }
                        }

                        return product.name !== undefined && <ImageListItem key={productid} sx={{overflow:"hidden"}}>
                                 <img src={product.image1} alt={product.name} style={{height:"150px"}} />
                                 <ImageListItemBar title={product.name} subtitle={"$"+ product.price} position="bottom" 
                                   
                                 />
                                </ImageListItem>
                    })}
                </ImageList>
             </TabPanel>
             <TabPanel name="auction" activeTab={activeTab}>
                    {auctions.map( (auction, idx) => {
                        
                        return <div key={auction.id} className="auction-item">
                            <h5>{auction.name}</h5>
                            
                            <div className="auction-details">
                                <p>Seller: {auction.collaborators.length >0 ? auction.collaborators[0].name : auction.createdByName} </p>
                                <p>Staring Bid: ${auction.startPrice}</p>
                                {auction.bids.length >0 ? 
                                    <p>
                                        Highest Bid: ${auction.bids[auction.bids.length-1].bid} by <strong>{auction.bids[auction.bids.length-1].displayname}</strong>
                                    </p>
                                : 
                                <p>No Bids</p>}
                                
                            </div>
                            <button onClick={ () => { placeAuctionOrder(auction.id, state.host)}}>Place Order</button>
                            </div>
                            
                        })

                    }
             </TabPanel>
             
             <TabPanel name="statistics" activeTab={activeTab}>
                <List>
                    <ListItem>
                        <ListItemText primary="Total Sales" secondary={"$" + totalSales.toFixed(2) + " ($" + netSales.toFixed(2) +" Net)"} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<div>Donations Received {sessionStorage.getItem("isAdmin") === "true"  &&<IconButton onClick={ () => downloadDonorList()}><DownloadingIcon sx={{color:"#000" }} /></IconButton>}</div>  } secondary={"$" + donations.toFixed(2)} />
                       
                    </ListItem>
                    
                     
                    <ListItem>
                        <ListItemText primary="Total No. Items Sold" secondary={itemsSold} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="No. of users" secondary={
                            <span>{noOfUsers} <a href="#" onClick={ () => setStatDialog(true)}>(stats)</a></span>} />
                    </ListItem>
                    <ListItem>
                        <Button onClick={ () => downloadChatLog()}>Download Chat Log</Button>  
                        <Button  onClick={ () => downloadUserList()} >Download User List</Button>
                    </ListItem>
                </List>
                <hr />
                <h4>Download Poll Results</h4>

                <List>
                {polls.map( (poll, idx) => {
                    return <ListItem><ListItemButton key={poll.id} onClick={ () => downloadPollData(poll.id)}><ListItemText primary={poll.name} /></ListItemButton></ListItem>
                })}
                </List>

             </TabPanel>

             <TabPanel name="recordings" activeTab={activeTab}>
                    <List  component="div">
                        {recordingUsers.map( (recording, idx) => { 
                            return <ListItem className="recording-user-item"><ListItemButton key={recording.uid} onClick={ (e) => {  getUserRecordedFiles(state.id, recording.uid);setExpandFiles(expandFiles === recording.uid ? "" : recording.uid); }}>
                                <ListItemIcon><Avatar alt={recording.displayname} src={recording.avatar} /></ListItemIcon>
                                    <ListItemText primary={recording.displayname}  />
                                    {expandFiles === recording.uid ? <ExpandLess /> : <ExpandMore /> }
                                   
                                </ListItemButton>
                                <Collapse in={expandFiles == recording.uid} timeout="auto" unmountOnExit>
                              
                                        <List component="div" disablePadding>
                                            {
                                                recordings.map( (recording, idx) => {
                                                    return <ListItemButton onClick={ () => { downloadVideo(recording.path)} }><ListItemText key={idx} primary={(idx+1) + ": Download" }   /></ListItemButton>
                                                    
                                                })
                                            }
                                              <ListItemText primary={"Total Files: "+recordings.length}></ListItemText>
                                        </List>
                                    </Collapse>
                                </ListItem>
                        })}
                        
                    </List>

                    <div>
                        <Button variant="contained" color="primary" endIcon={ <CleaningServicesIcon /> } onClick ={  () => cleanupTSFiles(state.id) } >Clean Up TS files</Button>
                    </div>
              </TabPanel>
                
                
            <div className="input-row form-actions">
                <Button variant="contained" color="primary" endIcon={ <SaveIcon /> } onClick={ (event) => { updateEvent(event) }}>
                    Save
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button variant="contained" color="info" endIcon={ <ClearIcon /> } onClick={ (event) => { cancelUpdate(event) } }>
                    Cancel
                </Button>
                

                </div>
                </div>
            </ConsoleTemplate>
            
            <Dialog fullScreen={true} open={productSelectOpen || auctionSelectOpen} onClose={ () => { setProductSelectOpen(false); setAuctionSelectOpen(false); }} >
                <DialogTitle>Add Product</DialogTitle>
                <DialogContent>
                <Container>
                <TextField sx={{marginTop: "10px" }}name="product-search" className="form-input-field" fullWidth={true} label="Search Product" placeholder="Search by product name..." value={productSearchText} onChange={(e) => onProductSearch(e.target.value)} />
                <ImageList spacing={5} id="product-select-list" rowHeight={150} cols={activeProducts.length <=4 ? activeProducts.length : 4} >
                    {activeProducts.filter( (product) => productSearchText === "" || product.name.toLowerCase().indexOf(productSearchText.toLowerCase()) >= 0).map( (product, index) => {

                        var checked = false;

                        if(productSelectOpen && state.products.includes(product.id)) {
                            checked = true;
                        }

                        if(auctionSelectOpen && state.productsInAuction.includes(product.id)) {
                            checked = true;
                        }

                        return (
                            <ImageListItem key={product.id} sx={{overflow:"hidden"}}>
                                <img src={product.image1} alt={product.name} style={{height:"150px"}}/>
                                <ImageListItemBar title={product.name} subtitle={"$" + product.price} actionIcon={
                                    <IconButton onClick={ () =>{ onAddProduct(product.id, !checked, auctionSelectOpen ) }}>
                                        {checked ? <CheckBoxIcon style={{color:"#fff"}} />:<CheckBoxOutlineBlankIcon style={{color:"#fff"}}/>}
                                    </IconButton>
                                } />
                            </ImageListItem>    
                        )
                    })}
                </ImageList>
            </Container>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="buttonDark" onClick={ () => { setProductSelectOpen(false); setAuctionSelectOpen(false);}} >Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullscreen={true} open={statDialog} onClose={ ()=>setStatDialog(false)}>
                <DialogTitle>User Statistics</DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem>App Version</ListItem>
                    </List>
                </DialogContent>
            </Dialog>
        </LocalizationProvider>
    )
}

export default EventEditView

